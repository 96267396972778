import React, { PureComponent } from "react";
import { withRouter } from "../../../../helpers/utility";
import PropTypes from "prop-types";
import ScrollIntoView from 'react-scroll-into-view'
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  NavItem,
  NavLink
} from "reactstrap";
import FontAwesome from "react-fontawesome";
import Translate from "../../../../hoc/Locale/Translate";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import classes from "./LoggedInNavigation.module.css";

class LoggedInNavigation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isMediaVisited: false,
      isVerlagVisited: false,
      isEmpfangVisited: false,
      isJobsVisited: false,
      isFreigabeVisited: false,
      isUserAccountVisited: false,
      isServiceVisited: false,
      isProductVisited: false,
      isCertifiedVisited: false
    };
  }

  componentDidMount() {
    this.highlightCreateJobLinkOnRefresh();
  }

  highlightCreateJobLinkOnRefresh() {
    const { location } = this.props.router;

    if (location && location.pathname) {
      if (location.pathname === "/jobs/neu-job") {
        this.onSubMenuItemClick("jobs");
      }
    }
  }

  showUserMenu() {
    this.setState(prevState => ({
      isUserMenuOpen: true,
      isServiceMenuOpen: false,
      isJobsMenuOpen: false,
      isProductMenuOpen: false
    }));
  }

  showServiceMenu() {
    this.setState(prevState => ({
      isUserMenuOpen: false,
      isServiceMenuOpen: true,
      isJobsMenuOpen: false,
      isProductMenuOpen: false
    }));
  }

  showJobsMenu() {
    this.setState(prevState => ({
      isUserMenuOpen: false,
      isServiceMenuOpen: false,
      isJobsMenuOpen: true,
      isProductMenuOpen: false
    }));
  }

  showProductMenu() {
    this.setState(prevState => ({
      isUserMenuOpen: false,
      isServiceMenuOpen: false,
      isJobsMenuOpen: false,
      isProductMenuOpen: true
    }));
  }

  hideMenus = () => {
    this.setState(prevState => ({
      isUserMenuOpen: false,
      isServiceMenuOpen: false,
      isJobsMenuOpen: false,
      isProductMenuOpen: false
    }));
  };

  setVisitedStyle = visitedLink => {
    this.props.visitLink();
    this.setState({
      isMediaVisited: false,
      isVerlagVisited: false,
      isEmpfangVisited: false,
      isJobsVisited: false,
      isFreigabeVisited: false,
      isServiceVisited: false,
      isUserAccountVisited: false,
      isProductVisited: false,
      isCertifiedVisited: false
    });

    switch (visitedLink) {
      case "medien":
        this.setState({
          isMediaVisited: true
        });
        this.hideMenus();
        break;
      case "verlage":
        this.setState({
          isVerlagVisited: true
        });
        this.hideMenus();
        break;
      case "certified":
        this.setState({
          isCertifiedVisited: true
        });
        this.hideMenus();
        break;
      case "empfang":
        this.setState({
          isEmpfangVisited: true
        });
        this.hideMenus();
        break;
      case "jobs":
        this.setState({
          isJobsVisited: true
        });
        break;
      case "freigabe":
        this.setState({
          isFreigabeVisited: true
        });
        this.hideMenus();
        break;
      case "useraccount":
        this.setState({
          isUserAccountVisited: true
        });
        break;
      case "service":
        this.setState({
          isServiceVisited: true
        });
        break;
      case "product":
        this.setState({
          isProductVisited: true
        });
        break;
      default:
        this.setState({
          isMediaVisited: false,
          isServiceVisited: false,
          isUserAccountVisited: false,
          isFreigabeVisited: false,
          isJobsVisited: false,
          isEmpfangVisited: false,
          isVerlagVisited: false,
          isProductVisited: false,
          isCertifiedVisited: false
        });
    }
  };

  visitReceiverJobList = () => {
    const { gridType, onLeaveJobList, onEnterJobList } = this.props;
/*     if (gridType !== "receiver") {
      onLeaveJobList();
    } */

    this.setVisitedStyle("empfang");
  //  onEnterJobList("receiver");
  };

  visitSenderJobList = menuItem => {
    const { gridType, onLeaveJobList, onEnterJobList } = this.props;
/*     if (gridType !== "sender") {
      onLeaveJobList();
    } */

    this.setVisitedStyle(menuItem);
    this.hideMenus();
//    onEnterJobList("sender");
  };

  onSubMenuItemClick = menuItem => {
    this.setVisitedStyle(menuItem);
    this.hideMenus();
  };

  onSubMenuItemClickWithAnchor = (menuItem) => {
    this.setVisitedStyle(menuItem);
    this.hideMenus();
  };

  render() {
    const {
      isUserMenuOpen,
      isServiceMenuOpen,
      isJobsMenuOpen,
      isProductMenuOpen,
      isMediaVisited,
      isVerlagVisited,
      isJobsVisited,
      isEmpfangVisited,
      isUserAccountVisited,
      isServiceVisited,
      isFreigabeVisited,
      isProductVisited,
      isCertifiedVisited
    } = this.state;
    const {
      isReceiver,
      isCompanyAdmin,
      appLanguage,
      isHomepageVisited
    } = this.props;

    let userMenuId = "UserMenu";
    let serviceMenuId = "ServiceMenu";
    let jobsMenuId = "JobsMenu";
    let productMenuId = "ProductMenu";
    return (
      <>
        {isReceiver && (
          <NavItem
            className={
              isEmpfangVisited && !isHomepageVisited
                ? classes.NavItemVisited
                : classes.NavItem
            }
          >
            <FontAwesome name="inbox" />
            <NavLink
              onClick={() => this.visitReceiverJobList()}
              className={[classes.NavLink, "nav-link"].join(" ")}
              to="/empfang"
              tag={RRNavLink}
              onMouseOver={() => this.hideMenus()}
            >
              <Translate id="Navigation.Empfang" />
            </NavLink>
          </NavItem>
        )}
        <NavItem
          className={
            isJobsVisited && !isHomepageVisited
              ? classes.NavItemVisited
              : classes.NavItem
          }
        >
          <FontAwesome name="paper-plane" />
          {
            <div
              id={jobsMenuId}
              style={{ cursor: "pointer" }}
              className={[classes.NavLink, "nav-link"].join(" ")}
              onMouseOver={() => this.showJobsMenu()}
            >
              <Translate id="Navigation.Jobs" />
            </div>
          }
        </NavItem>
        <Popover
          placement="bottom"
          isOpen={isJobsMenuOpen}
          target={jobsMenuId}
          toggle={() => this.hideMenus()}
          
        >
          <PopoverHeader></PopoverHeader>
          <PopoverBody onMouseLeave={() => this.hideMenus()}>
            <div className={classes.UserMenu}>
              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() => this.visitSenderJobList("jobs")}
                  className={classes.UserMenuNavLink}
                  to="/jobs"
                  tag={RRNavLink}
                >
                  <FontAwesome name="list" />
                  &nbsp;&nbsp;
                  <Translate id="Navigation.Joblist" />
                </NavLink>
              </NavItem>
              <NavItem className={classes.UserMenuNavItem}>
                <a
                  className="nav-link"
                  href="/jobs/neu-job"
                  style={{ textTransform: "uppercase" }}
                >
                  <FontAwesome name="plus" />
                  &nbsp;&nbsp;
                  <Translate id="Navigation.CreateJob" />
                </a>
              </NavItem>
            </div>
          </PopoverBody>
        </Popover>

        <NavItem
          className={
            isMediaVisited && !isHomepageVisited
              ? classes.NavItemVisited
              : classes.NavItem
          }
        >
          <FontAwesome name="leanpub" />
          <NavLink
            onClick={() => this.setVisitedStyle("medien")}
            className={classes.NavLink}
            to="/medien"
            onMouseOver={() => this.hideMenus()}
            tag={RRNavLink}
          >
            <Translate id="Navigation.Media" />
          </NavLink>
        </NavItem>
        {this.props.hasFreigabeRole && (
          <NavItem
            className={
              isFreigabeVisited
                ? [classes.NavItem, classes.NavItemVisited].join(" ")
                : classes.NavItem
            }
          >
            <FontAwesome name="unlock" />
            <NavLink
              onClick={() => this.setVisitedStyle("freigabe")}
              className={classes.NavLink}
              to="/freigabe"
              tag={RRNavLink}
            >
              <Translate id="Navigation.Freigabe" />
            </NavLink>
          </NavItem>
        )}

        <NavItem
          className={
            isVerlagVisited && !isHomepageVisited
              ? classes.NavItemVisited
              : classes.NavItem
          }
        >
          <FontAwesome name="building" />
          <NavLink
            onClick={() => this.setVisitedStyle("verlage")}
            className={classes.NavLink}
            to={appLanguage === "en" ? "/publishers" : "/verlage"}
            tag={RRNavLink}
            onMouseOver={() => this.hideMenus()}
          >
            <Translate id="Navigation.Verlage" />
          </NavLink>
        </NavItem>

        <NavItem
          className={
            isCertifiedVisited && !isHomepageVisited
              ? classes.NavItemVisited
              : classes.NavItem
          }
        >
          <FontAwesome name="graduation-cap" />
          <NavLink
            onClick={() => this.setVisitedStyle("certified")}
            className={classes.NavLink}
            to="/certified"
            tag={RRNavLink}
            onMouseOver={() => this.hideMenus()}
          >
            <Translate id="Navigation.Certified" />
          </NavLink>
        </NavItem>

        <NavItem
          className={
            isProductVisited ? classes.NavItemVisited : classes.NavItem
          }
        >
          <FontAwesome name="paper-plane" />
          {
            <div
              id={productMenuId}
              style={{ cursor: "pointer" }}
              className={[classes.NavLink, "nav-link"].join(" ")}
              onMouseOver={() => this.showProductMenu()}
            >
              <Translate id="Navigation.Produkt" />
            </div>
          }
        </NavItem>

        <Popover
          placement="bottom"
          isOpen={isProductMenuOpen}
          target={productMenuId}
          toggle={() => this.hideMenus()}
        >
          <PopoverHeader></PopoverHeader>
          <PopoverBody onMouseLeave={() => this.hideMenus()}>
            <div className={classes.UserMenu}>
            <ScrollIntoView selector={"#vorteile"} scrollOptions={{behavior: "instant"}}>
              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() =>
                    this.onSubMenuItemClickWithAnchor("product")
                  }
                  className={classes.UserMenuNavLink}
                  to="/#vorteile"
                  tag={RRNavLink}
                >
                  <FontAwesome name="plus-circle" />
                  &nbsp;&nbsp; <Translate id="Home.Vorteile" />
                </NavLink>
              </NavItem>
            </ScrollIntoView>
            
            <ScrollIntoView selector={"#preise"} scrollOptions={{behavior: "instant"}}>
              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() =>
                    this.onSubMenuItemClickWithAnchor("product")
                  }
                  className={classes.UserMenuNavLink}
                  to="/#preise"
                  tag={RRNavLink}
                >
                  <FontAwesome name="tag" />
                  &nbsp;&nbsp; <Translate id="Home.Preise" />
                </NavLink>
              </NavItem>
              </ScrollIntoView>

              <ScrollIntoView selector={"#teilnehmen"} scrollOptions={{behavior: "instant"}}>
                <NavItem className={classes.UserMenuNavItem}>
                  <NavLink
                    onClick={() =>
                      this.onSubMenuItemClickWithAnchor("product")
                    }
                    className={classes.UserMenuNavLink}
                    to="/#teilnehmen"
                    tag={RRNavLink}
                  >
                    <FontAwesome name="play-circle" />
                    &nbsp;&nbsp; <Translate id="Home.Teilnehmen" />
                  </NavLink>
                </NavItem>
              </ScrollIntoView>
            </div>
          </PopoverBody>
        </Popover>

        <NavItem
          className={
            isServiceVisited ? classes.NavItemVisited : classes.NavItem
          }
        >
          <FontAwesome name="wrench" />
          {
            <div
              id={serviceMenuId}
              style={{ cursor: "pointer" }}
              className={[classes.NavLink, "nav-link"].join(" ")}
              onMouseOver={() => this.showServiceMenu()}
            >
              Service
            </div>
          }
        </NavItem>
        <Popover
          placement="bottom"
          isOpen={isServiceMenuOpen}
          target={serviceMenuId}
          toggle={() => this.hideMenus()}
        >
          <PopoverHeader></PopoverHeader>
          <PopoverBody onMouseLeave={() => this.hideMenus()}>
            <div className={classes.UserMenu}>
              <NavItem className={classes.UserMenuNavItem}>
                <a
                  className="nav-link"
                  href="http://duon-portal.blog/"
                  target="blank"
                  style={{ textTransform: "uppercase" }}
                >
                  <FontAwesome name="quote-right" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.Blog" />
                </a>
              </NavItem>
              <NavItem className={classes.UserMenuNavItem}>
                <a
                  className="nav-link"
                  href="https://api.duon-portal.de/"
                  target="blank"
                  style={{ textTransform: "uppercase" }}
                >
                  <FontAwesome name="wifi" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.API" />
                </a>
              </NavItem>
              <NavItem className={classes.UserMenuNavItem}>
                <a
                  className="nav-link"
                  href="https://duon.zendesk.com"
                  target="blank"
                  style={{ textTransform: "uppercase" }}
                >
                  <FontAwesome name="life-saver" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.Help" />
                </a>
              </NavItem>
            </div>
          </PopoverBody>
        </Popover>

        <NavItem
          className={
            isUserAccountVisited && !isHomepageVisited
              ? classes.NavItemVisited
              : classes.NavItem
          }
        >
          <FontAwesome name="user-circle-o" />
          {
            <div
              id={userMenuId}
              style={{ cursor: "pointer" }}
              className={[classes.NavLink, "nav-link"].join(" ")}
              onMouseOver={() => this.showUserMenu()}
            >
              Account
            </div>
          }
        </NavItem>
        <Popover
          placement="bottom"
          isOpen={isUserMenuOpen}
          target={userMenuId}
          toggle={() => this.hideMenus()}
        >
          <PopoverHeader></PopoverHeader>
          <PopoverBody onMouseLeave={() => this.hideMenus()}>
            <div className={classes.UserMenu}>
              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() => this.onSubMenuItemClick("useraccount")}
                  className={classes.UserMenuNavLink}
                  to="/benutzer/mein-profil"
                  tag={RRNavLink}
                >
                  <FontAwesome name="user" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.Profile" />
                </NavLink>
              </NavItem>

              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() => this.onSubMenuItemClick("useraccount")}
                  className={classes.UserMenuNavLink}
                  to="/benutzer/zugewiesene-medien"
                  tag={RRNavLink}
                >
                  <FontAwesome name="leanpub" />
                  &nbsp;&nbsp;
                  <Translate id="Navigation.MyMedia" />
                </NavLink>
              </NavItem>

              {isCompanyAdmin && (
                <NavItem className={classes.UserMenuNavItem}>
                  <NavLink
                    onClick={() => this.onSubMenuItemClick("useraccount")}
                    className={classes.UserMenuNavLink}
                    to="/benutzer/meine-firma"
                    tag={RRNavLink}
                  >
                    <FontAwesome name="users" />
                    &nbsp;&nbsp;
                    <Translate id="Navigation.ManageCompanyUsers" />
                  </NavLink>
                </NavItem>
              )}

              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() => this.onSubMenuItemClick("useraccount")}
                  className={classes.UserMenuNavLink}
                  to="/benutzer/neues-passwort"
                  tag={RRNavLink}
                >
                  <FontAwesome name="shield" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.ChangePassword" />
                </NavLink>
              </NavItem>

              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() => this.onSubMenuItemClick("useraccount")}
                  className={classes.UserMenuNavLink}
                  to="/benutzer/settings"
                  tag={RRNavLink}
                >
                  <FontAwesome name="cog" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.Settings" />
                </NavLink>
              </NavItem>

              <NavItem className={classes.UserMenuNavItem}>
                <NavLink
                  onClick={() => this.props.onLogout()}
                  className={classes.UserMenuNavLink}
                  to="/"
                  tag={RRNavLink}
                >
                  <FontAwesome name="power-off" />
                  &nbsp;&nbsp;&nbsp;
                  <Translate id="Navigation.Logout" />
                </NavLink>
              </NavItem>
            </div>
          </PopoverBody>
        </Popover>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout())
  };
};

LoggedInNavigation.propTypes = {
  gridType: PropTypes.string,
  isHomepageVisited: PropTypes.bool,
  isReceiver: PropTypes.bool,
  isCompanyAdmin: PropTypes.bool,
  appLanguage: PropTypes.string,
  hasFreigabeRole: PropTypes.bool,
  onEnterJobList: PropTypes.func,
  onLeaveJobList: PropTypes.func,
  visitLink: PropTypes.func
};

export default withRouter(connect(
  null,
  mapDispatchToProps
)(LoggedInNavigation));
